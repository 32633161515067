import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import API from './api'

export interface Booking {
  firstName: string
  lastName: string
  clinicId: string
  bookingId: string
  clinicName: string
  appointment: string
}

export interface BookingState {
  isOnDemand: boolean
  setIsOnDemand: (isOnDemand: boolean) => void
  bookings: Booking[]
  bookingPhoneNumber?: string
  setBookingPhoneNumber: (bookingPhoneNumber: string) => void
  setBookings: (bookings: Booking[]) => void
  fetchBookings: () => Promise<void>
}

const useBookingStore = create<BookingState>()(
  persist(
    (set, get) => ({
      isOnDemand: false,
      setIsOnDemand: (isOnDemand: boolean) => {
        set(() => ({ isOnDemand }))
      },
      bookings: [],
      setBookings: (bookings: Booking[]) => {
        set(() => ({ bookings }))
      },
      bookingPhoneNumber: undefined,
      setBookingPhoneNumber: (bookingPhoneNumber?: string) => {
        set(() => ({ bookingPhoneNumber }))
      },
      fetchBookings: async () => {
        const { bookingPhoneNumber } = get()
        const bookings = await API.fetchBookings(bookingPhoneNumber)
        console.info('bookings', bookings)
        set(() => ({ bookings }))
      },
    }),
    {
      name: 'booking-storage',
    },
  ),
)

export default useBookingStore
