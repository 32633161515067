import { Helper, ModelBooking, ModelPaperwork } from 'src/_Shared/global'
import { stringOrNull } from './utils'
import accountStore from '../account'

const API = {
  async fetchBookings(phoneNumber?: string) {
    const account = accountStore.getState().account
    const paperwork = ModelPaperwork.paperwork
    const booker = ModelBooking.getBooker()

    const phone =
      stringOrNull(phoneNumber) ??
      stringOrNull(account?.phoneNumber) ??
      stringOrNull(paperwork?.account?.phone) ??
      stringOrNull(booker?.phone) ??
      ''
    if (phone?.length > 0) {
      const results = await ModelBooking.findAll(phone)
      console.log('[API.fetchBookings] results:', results)
      const upcomingBookings = results.filter(
        (booking) => new Date(booking.appointment) > new Date(),
      )
      // Convert the appointment date to a readable format
      const withConvertedDates = upcomingBookings.map((booking) => {
        return {
          ...booking,
          appointment: Helper.convertAppointmentDate(booking.appointment),
        }
      })

      // If the booking summary is loaded, update it with the latest data
      const foundBooking = results.find(
        (booking) => booking.bookingId === ModelBooking.bookingId,
      )
      if (foundBooking) ModelBooking.setBookingSummary(foundBooking)
      return withConvertedDates
    }
  },
}

export default API
