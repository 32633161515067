//:::::::::::::::::::::::::
import css from './home.module.css'
import {
  ModelBooking,
  Icon,
  Button,
  useCallback,
  Types,
  useNavigate,
  Path,
  ModelVirtualCare,
  useEffect,
} from 'src/_Shared/global'
import { useBookingStore } from 'src/_Shared/stores/booking'
//:::::::::::::::::::::::::

export default function UpcomingVisits() {
  const navigate = useNavigate()

  //==============================
  //#region Load Bookings
  //==============================
  const [bookings, fetchBookings] = useBookingStore((state) => [
    state.bookings,
    state.fetchBookings,
  ])
  useEffect(() => {
    fetchBookings()
  }, [fetchBookings])

  //==============================
  //#region Select Booking
  //==============================
  const clickBooking = useCallback((booking: Types.BookingSummary) => {
    const clinics: string[] = Object.values(
      ModelVirtualCare.getVirtualClinics(),
    )
    if (clinics.includes(booking.clinicId)) {
      localStorage.setItem('virtualBookingId', booking.bookingId)
    }
    //Save booking summary to cache so it can be loaded on the BookFinish screen
    ModelBooking.setBookingSummary(booking)
    //>>>>>
    navigate(Path.BookFinish)
  }, [])

  return (
    <div className={css.visits}>
      {bookings?.length ? (
        <>
          <h2>Upcoming Visits</h2>
          <ul>
            {bookings.map((booking) => (
              <li key={booking.bookingId}>
                <Button clicked={() => clickBooking(booking)} block>
                  <div className={css.wrap}>
                    <span>
                      <Icon name='medicalCalendar' width={70} />
                    </span>
                    <section>
                      <h3>NextCare Urgent Care</h3>
                      <p>{booking.clinicName}</p>
                      <b>{booking.appointment}</b>
                    </section>
                    <span>
                      <Icon name='arrowRight' width={20} />
                    </span>
                  </div>
                </Button>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
