//:::::::::::::::::::::::::
import css from './bookPatientCss.module.css'
import {
  Nav,
  ModelBooking,
  useState,
  Button,
  useCallback,
  useEffect,
  observer,
  Types,
  useNavigate,
  ModelClinic,
  Path,
  Helper,
  FirebaseAnalytics,
  ModelModal,
} from 'src/_Shared/global'
import BookInfoClinic from '../3-BookInfo/BookInfoClinic'
import BookPatientList from './BookPatientList'
import BookPatientReason from './BookPatientReason'
import BookPatientEmployee from './BookPatientEmployee'
import BookPatientForm from './BookPatientForm'
import { useAccountStore } from 'src/_Shared/stores/account'
//:::::::::::::::::::::::::

function BookPatient() {
  const navigate = useNavigate()
  const [account] = useAccountStore((state) => [state.account])
  //==============================
  //#region Analytics
  //==============================
  useEffect(() => {
    FirebaseAnalytics.trigger('Book Now - Patient Information', {
      virtual_screen_name: 'Patient Information - You',
      clinic_name: ModelClinic.selected?.details.title,
      visit_type: localStorage.getItem('visitType') === 'WI' ? 'WI' : 'UC',
    })
  }, [])

  //==============================
  //#region State
  //==============================
  const [reason, setReason] = useState('')
  const [readyReason, setReadyReason] = useState<boolean | undefined>()

  const [patient, patientSet] = useState<Types.Patient>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    phone: '',
    email: '',
    relationship: '',
    nextGenId: '',
  })
  const [patients, patientsSet] = useState<Types.Patient[]>([])
  const [patientFormReady, setPatientFormReady] = useState<boolean>(false)
  const [selectedPatientIndex, selectedPatientIndexSet] = useState(0)

  const [employee, setEmployee] = useState('')
  const [readyEmployee, setReadyEmployee] = useState<boolean | undefined>()

  //==============================
  //#region Build Patient List
  //==============================
  useEffect(() => {
    var patientsReady: Types.Patient[] = []
    //:: 1 :: Load default patient from booker info
    if (localStorage.getItem('booker')) {
      const bookerPatient = JSON.parse(
        localStorage.getItem('booker')!,
      ) as Types.Patient

      //---
      patientsReady.push(bookerPatient)
      //Set them as the default selected patient
      patientSet(bookerPatient)
    }

    //:: 2 :: Get Dependents
    for (const dependent of account?.dependents ?? []) {
      //Convert Dependent object to patient
      const patient: Types.Patient = {
        firstName: dependent.firstName ?? '',
        lastName: dependent.lastName ?? '',
        dateOfBirth: Helper.convertDateOfBirthFromAPI(
          dependent.dateOfBirth ?? '',
        ),
        phone: Helper.convertPhoneFromAPI(account?.phoneNumber ?? ''),
        email: account?.email ?? '',
        nextGenId: dependent.nextGenPersonId ?? '',
      }
      //---
      patientsReady.push(patient)
    }

    //:: 3 :: Someone Else
    var phone = ''
    var email = ''
    //Use the booker's phone and email if they are adding a dependent
    if (localStorage.getItem('booker')) {
      const booker = JSON.parse(
        localStorage.getItem('booker')!,
      ) as Types.Patient
      phone = booker.phone
      email = booker.email
    }

    patientsReady.push({
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phone: phone,
      email: email,
      relationship: '',
    })

    //:::
    patientsSet(patientsReady)
  }, [])

  //==============================
  //#region Check Cache
  //==============================
  useEffect(() => {
    const noSelected = ModelBooking.virtualCare
      ? !ModelClinic.getSelectedVirtual()
      : !ModelClinic.getSelected()
    if (noSelected || !ModelBooking.getAppointment()) {
      //>>>>>
      navigate(Path.BookStart)
    }
  }, [navigate])

  //==============================
  //#region Form Validation
  //==============================
  const [formReady, setFormReady] = useState(false)

  useEffect(() => {
    let ready = !!readyReason && !!readyEmployee

    if (selectedPatientIndex === patients.length - 1) {
      ready = patientFormReady
    }

    //:::
    setFormReady(ready)
  }, [readyReason, readyEmployee, patientFormReady, selectedPatientIndex])

  //Set the current patient based on the index
  useEffect(() => {
    patientSet(patients[selectedPatientIndex])
    //%%%%% Analytics
    if (selectedPatientIndex === patients.length - 1) {
      //If "Someone Else" selected...
      setPatientFormReady(
        Helper.isNotEmpty(patients[selectedPatientIndex].firstName) &&
          Helper.isNotEmpty(patients[selectedPatientIndex].lastName) &&
          Helper.isAValidBirthDate(patients[selectedPatientIndex].dateOfBirth),
      )
      FirebaseAnalytics.trigger('Book Now - Patient Information', {
        virtual_screen_name: 'Patient Information - Someone Else',
        clinic_name: ModelClinic.selected?.details.title,
        visit_type: localStorage.getItem('visitType') === 'WI' ? 'WI' : 'UC',
      })
    }
  }, [selectedPatientIndex, patients])

  //==============================
  //#region Continue
  //==============================
  const clickFinishBooking = useCallback(async () => {
    if (!ModelBooking.getAppointment()) return

    //Check for a booking ID to update
    if (localStorage.getItem('bookingId')) {
    }

    //Save patient
    localStorage.setItem('patient', JSON.stringify(patient))

    //Save newly added dependent to localStorage if they selected "Someone Else"
    if (selectedPatientIndex === patients.length - 1) {
      localStorage.setItem('newDependent', JSON.stringify(patient))
    } else {
      //Remove dependent
      localStorage.removeItem('newDependent')
    }

    //Save the NextCare employee and reason
    localStorage.setItem('employee', employee)
    localStorage.setItem('reason', reason)

    //----------
    //Assemble booking summary for book finish screen
    const bookingId = await ModelBooking.create()
    if (bookingId) {
      const practiceDetails = ModelClinic.getSelectedVirtual()?.practiceDetails
      const clinic = JSON.parse(localStorage.getItem('clinic')!) as Types.Clinic
      //Save booking summary
      const summary: Types.BookingSummary = {
        firstName: patient.firstName,
        lastName: patient.lastName,
        appointment: localStorage.getItem('appointment')!,
        bookingId: bookingId,
        clinicName:
          ModelBooking.virtualCare && practiceDetails
            ? practiceDetails.name
            : clinic.details.title,
        clinicId:
          ModelBooking.virtualCare && practiceDetails
            ? practiceDetails.id
            : clinic.id,
      }
      //Persist booking summary for next screen
      ModelBooking.setBookingSummary(summary)
      //Save booking ID for changing the booking
      ModelBooking.setBookingId(bookingId)
      ModelModal.hide()
      //>>>>>
      navigate(Path.BookFinish)
    }
    //----------
  }, [reason, employee, navigate, patient, patients, selectedPatientIndex])

  //==============================
  //#region UI
  //==============================
  return (
    <>
      <Nav title='Patient Information' back={Path.BookInfo} />
      <BookInfoClinic />
      <div className={css.bookPatient}>
        {/* === Patient & Dependent List === */}
        <BookPatientList
          patients={patients}
          selected={selectedPatientIndex}
          select={(index) => {
            selectedPatientIndexSet(index)
          }}
        />

        {/* === Patient Form === */}
        {selectedPatientIndex === patients.length - 1 && (
          <BookPatientForm
            values={patient}
            change={(values) => {
              setPatientFormReady(
                Helper.isNotEmpty(values.firstName) &&
                  Helper.isNotEmpty(values.lastName) &&
                  Helper.isAValidBirthDate(values.dateOfBirth),
              )
              patientSet(values)
            }}
          />
        )}

        {/* === Reason === */}
        <BookPatientReason
          valid={readyReason}
          change={(value) => {
            setReason(value)
            setReadyReason(Helper.isNotEmpty(value))
          }}
        />

        {/* === NextCare Employee Check === */}
        <BookPatientEmployee
          setEmployeeName={(value: string) => setEmployee(value)}
          setReadyEmployee={(value: boolean) => setReadyEmployee(value)}
        />

        {/* === Continue Button === */}
        <div className={css.bookPatientButton}>
          <Button
            primary
            text='Finish Booking'
            disabled={!formReady}
            clicked={clickFinishBooking}
          />
        </div>
      </div>
    </>
  )
}

export default observer(BookPatient)
