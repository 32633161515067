//:::::::::::::::::::::::::
import css from './bookFinish.module.css'
import {
  Step,
  Path,
  ModelPaperwork,
  useNavigate,
  ModelBooking,
  useCallback,
  useEffect,
  useState,
  Helper,
  Types,
  ModelVirtualCare,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function BookNextSteps(props: {
  paperworkDone: boolean
  checkedIn: boolean
  checkBookingStatus: (bookingId: string) => Promise<void>
}) {
  const navigate = useNavigate()
  const [checkInAvailable, setCheckInAvailable] = useState(false)

  const clickCheckIn = useCallback(async () => {
    const bookingId = localStorage.getItem('bookingId')
    if (bookingId) {
      await ModelBooking.confirm(bookingId)
      await props.checkBookingStatus(bookingId)
    }
    //>>>>>
    navigate(`${Path.BookFinish}?promo=yes`)
  }, [navigate])

  //==============================
  //#region Check Appointment Time
  //==============================
  //Check-in is disabled until 15 minutes before the appointment starts
  useEffect(() => {
    const intervalId = setInterval(async () => {
      const bookingSummaryRaw = localStorage.getItem('bookingSummary')
      const bookingSummary = bookingSummaryRaw
        ? (JSON.parse(bookingSummaryRaw!) as Types.BookingSummary)
        : null
      await props.checkBookingStatus(bookingSummary?.bookingId!)
      if (bookingSummary?.appointment) {
        const result = Helper.isAppointmentFifteenMinutesAway(
          bookingSummary.appointment,
        )
        setCheckInAvailable(result)
      }
    }, 30000)
    return () => clearInterval(intervalId)
  }, [])

  //==============================
  //#region Join
  //==============================
  const clickVirtualStart = useCallback(async () => {
    const bookingId = localStorage.getItem('bookingId')
    //Redirect to Solv to join meeting
    //TODO make the root domain an environment variable?
    window.location.href = `https://mapp-livestage.solvhealth.com/video-visit/${bookingId}/pre-visit-ready?skip_onboarding=true`
  }, [])

  const bookingSummary = ModelBooking.getBookingSummary()
  const isVirtualCare = ModelVirtualCare.isVirtualCare(bookingSummary?.clinicId)

  //==============================
  //#region UI
  //==============================
  return (
    <>
      {/* === Next Steps === */}
      <div className={css.nextSteps}>
        <h2>Next steps:</h2>

        <Step
          number='1'
          title='Complete Paperwork'
          text='Please fill out your information before your visit. We’ll save the details for next time.'
          button={
            !props.paperworkDone
              ? isVirtualCare
                ? 'Continue'
                : 'Start Paperwork'
              : undefined
          }
          buttonClicked={() => {
            if (!props.paperworkDone) {
              //Clear paperwork cache
              ModelPaperwork.clear()
              if (isVirtualCare) {
                //>>>>>
                navigate(Path.PaperworkVirtual1)
              } else {
                //>>>>>
                navigate(Path.Paperwork1)
              }
            }
          }}
          selected={props.paperworkDone}
        />
        <Step
          number='2'
          title={isVirtualCare ? 'Join Call' : 'Check-in online'}
          text={
            isVirtualCare
              ? 'Connect with your provider by clicking the button below.'
              : "Use your mobile device to let the clinic know when you've arrived."
          }
          button={
            props.paperworkDone && !props.checkedIn
              ? isVirtualCare
                ? 'Join the Call'
                : "I'm Here"
              : undefined
          }
          buttonClicked={isVirtualCare ? clickVirtualStart : clickCheckIn}
          selected={props.checkedIn}
          disabled={!checkInAvailable}
        />
      </div>
    </>
  )
}
