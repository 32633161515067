//:::::::::::::::::::::::::
import css from './bookFinish.module.css'
import {
  Nav,
  Helper,
  Icon,
  Divider,
  Checklist,
  ModelBooking,
  ModelClinic,
  useNavigate,
  useEffect,
  useCallback,
  ModelModal,
  observer,
  Button,
  Path,
  Types,
  useState,
  FirebaseAnalytics,
  ModelPaperwork,
  useSearchParams,
  ModelVirtualCare,
} from 'src/_Shared/global'
import BookSomeoneElse from './BookSomeoneElse'
import BookNextSteps from './BookNextSteps'
import DownloadAppCard from 'src/_Shared/components/DownloadAppCard/DownloadAppCard'
//:::::::::::::::::::::::::

function BookFinish() {
  const navigate = useNavigate()
  const [params] = useSearchParams('promo')

  const bookingSummary = ModelBooking.getBookingSummary()
  const isVirtualCare = ModelVirtualCare.isVirtualCare(bookingSummary?.clinicId)

  useEffect(() => {
    // We need all clinics to start
    const getClinics = async () => {
      await ModelClinic.getAllClinics()
    }
    getClinics()
  }, [])

  //==============================
  //#region Analytics
  //==============================
  useEffect(() => {
    FirebaseAnalytics.trigger('Book a Visit - Visit Booked', {
      virtual_screen_name: 'Thank You - Visit Booked',
      visit_type: localStorage.getItem('visitType') === 'WI' ? 'WI' : 'UC',
    })
  }, [])

  //==============================
  //#region State
  //==============================
  const [summary, setSummary] = useState('')
  const [clinicName, setClinicName] = useState('')
  const [paperworkDone, setPaperworkDone] = useState(false)
  const [showAppPromo, setShowAppPromo] = useState(false)
  const [checkedIn, setCheckedIn] = useState(false)

  //==============================
  //#region Directions
  //==============================
  const clickDirections = useCallback(() => {
    let directions = `${ModelClinic.selected?.details.geo.latitude},${ModelClinic.selected?.details.geo.longitude}`

    // Virtual Care directions
    if (isVirtualCare) {
      const practiceDetails = ModelClinic.getSelectedVirtual()?.practiceDetails
      if (!practiceDetails) return
      const latLong = ModelClinic.getLatLongFromPracticeDetails(practiceDetails)
      directions = `${latLong.lat},${latLong.long}`
    }

    window.location.href = `https://maps.google.com/?q=${directions}`
  }, [])

  //==============================
  //#region Call Clinic
  //==============================
  const clickCall = useCallback(() => {
    const phoneNumber = isVirtualCare
      ? ModelClinic.selectedVirtual?.practiceDetails?.phone
      : ModelClinic.selected?.details.phone_number
    window.location.href = `tel:${phoneNumber}`
  }, [])

  //==============================
  //#region Cancel Booking
  //==============================
  const clickCancel = useCallback(() => {
    ModelModal.showCancelBooking()
  }, [])

  //==============================
  //#region Change Booking
  //==============================
  const clickChange = useCallback(() => {
    //>>>>>
    // We want to go back to the BookTime screen with the selected clinic
    const selectedClinic = ModelClinic.clinics.find(
      (clinic) =>
        bookingSummary?.clinicId && clinic.id === bookingSummary?.clinicId,
    )
    if (selectedClinic) {
      ModelClinic.setSelected(selectedClinic)
    }
    if (bookingSummary?.appointment) {
      ModelBooking.setAppointment(new Date(bookingSummary.appointment))
    }
    navigate(
      `${Path.BookTimeChange}?clinic=${
        selectedClinic?.id ?? bookingSummary?.clinicId
      }`,
    )
  }, [navigate])

  //==============================
  //#region Booking Details
  //==============================
  useEffect(() => {
    //Patient Name
    let name = ''
    if (localStorage.getItem('patient')) {
      const patient = JSON.parse(
        localStorage.getItem('patient')!,
      ) as Types.Patient
      name = Helper.abbreviateName(patient.firstName, patient.lastName)
    }

    //Appointment
    let appointment = 'No Appointment Found'
    const summary = ModelBooking.getBookingSummary()

    if (summary) {
      name = Helper.abbreviateName(summary.firstName, summary.lastName)

      appointment = Helper.convertAppointmentDate(summary.appointment)
      //Clinic
      setClinicName(summary.clinicName)

      // Ensure correct bookingId is set (when navigating here from Home)
      ModelBooking.setBookingId(summary.bookingId)
    }

    //:::
    setSummary(`${name} for ${appointment}`)
  }, [navigate])

  const checkBookingStatus = async (bookingId: string) => {
    const details = await ModelBooking.getBookingDetails(bookingId)
    setCheckedIn(details?.status === 'confirmed')
  }

  //==============================
  //#region Paperwork & Booking Status
  //==============================
  useEffect(() => {
    async function status(bookingId: string) {
      ModelModal.showLoader()
      //Paperwork Status - 26xmXo
      const paperworkStatus = await ModelPaperwork.getStatus(bookingId)
      console.info('Paperwork Status:', paperworkStatus)
      setPaperworkDone(paperworkStatus === 'complete')

      //Booking Status
      await checkBookingStatus(bookingId)

      ModelModal.hide()
    }
    //Get locally saved booking ID
    const bookingId = localStorage.getItem('bookingId')
    if (bookingId) {
      status(bookingId)
    }
  }, [])

  //==============================
  //#region Promo
  //==============================
  useEffect(() => {
    const promo = params.get('promo')
    setShowAppPromo(promo === 'yes')
  }, [params])

  //==============================
  //#region UI
  //==============================
  return (
    <>
      {/* === App Download Promo that covers the whole screen === */}
      {showAppPromo && <DownloadAppCard hide={() => setShowAppPromo(false)} />}

      <Nav title='Visit Booked' back={Path.Home} />
      <div className={css.finish}>
        {/* === Top Box Area === */}
        <div className={css.topBox}>
          <p>Your visit is booked</p>
          <h1>{summary}</h1>
          <div className={css.fancyBar}>&mdash;</div>

          <p>NextCare Urgent Care</p>
          <p>{clinicName}</p>

          <div className={css.buttons}>
            {/* === Directions (Open Google Maps) === */}
            <Button clicked={clickDirections}>
              <span>
                <Icon name='circleDirections' width={40} />
              </span>
              <cite>Directions</cite>
            </Button>
            {/* === Contact (Phone) === */}
            <Button clicked={clickCall}>
              <span>
                <Icon name='circleContact' width={40} />
              </span>
              <cite>Contact</cite>
            </Button>
            {!checkedIn && (
              <>
                {/* === Show Cancel Appointment Modal === */}
                <Button clicked={clickCancel}>
                  <span>
                    <Icon name='circleCancel' width={40} />
                  </span>
                  <cite>Cancel</cite>
                </Button>
                {/* === Change Appointment Screen === */}
                <Button clicked={clickChange}>
                  <span>
                    <Icon name='circleChange' width={40} />
                  </span>
                  <cite>Change</cite>
                </Button>
              </>
            )}
          </div>
        </div>

        {/* === Next Steps / Paperwork === */}
        <BookNextSteps
          paperworkDone={paperworkDone}
          checkedIn={checkedIn}
          checkBookingStatus={checkBookingStatus}
        />

        <Divider />
        {/* === Tips === */}
        <div className={css.tips}>
          <h2>Tips for a smooth visit:</h2>
          <Checklist
            items={[
              'Arrive 10 minutes early, especially if you’re a new patient.',
              'Make a list of questions you have for your provider and bring a way to take notes.',
              'Bring your government-issued photo ID.',
              'If you’re using insurance, take your insurance card with you.',
              'Bring your debit or credit card for any fees due at time of service.',
            ]}
          />
        </div>
        <Divider />
        {/* === Book Again === */}
        <BookSomeoneElse />
      </div>
    </>
  )
}

export default observer(BookFinish)
