import { Types } from 'src/_Shared/Types'

export const USA_ID = '13b3af53-f290-4f2b-b0ac-a81c64421d9a'
export const Canada_ID = '3a2011eb-8425-47b4-add9-4b6cfe3021a1'

//==============================
//#region Form Values
//==============================
export const STATES: string[] = [
  'AA',
  'AE',
  'AL',
  'AK',
  'AP',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WI',
  'WV',
]

export const PROVINCES: string[] = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NS',
  'NT',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
  'FC',
]

export const RACES: Types.Race[] = [
  {
    id: 'fb8a0bef-605f-45bd-b61a-9c0b23ea8c5f',
    name: 'Undisclosed', // We will only send "Unknown" as a string (where applicable) when the user selects this option
  },
  {
    id: 'd9173865-1fe0-46fb-af97-60b5b4197d3d',
    name: 'American Indian or Alaska Native',
  },
  {
    id: '1c300a6f-3f40-4bfd-bd2b-6667c21292d3',
    name: 'Asian',
  },
  {
    id: '200e7794-f979-416a-b24d-bb92a016af9e',
    name: 'Black or African American',
  },
  {
    id: 'd32ce189-b22c-4826-9399-2516dc884d43',
    name: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    id: 'f4614c2d-13b9-49c6-8ee2-2b3bc92af8bc',
    name: 'White',
  },
]

export const ETHNICITIES: Types.Ethnicity[] = [
  {
    id: '05bf28f9-a600-445f-add2-ffccb0440a62',
    name: 'Undisclosed', // We will only send "Unknown" as a string (where applicable) when the user selects this option
  },
  {
    id: '625625c5-328f-4b49-a87e-0236777a3eff',
    name: 'Hispanic or Latino',
  },
  {
    id: 'bf23ab7f-c9a6-4930-acb6-710cd6d1a077',
    name: 'Not Hispanic or Latino',
  },
]

export const sexOptions: Types.Sex[] = ['Male', 'Female', 'Other']
